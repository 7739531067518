import CookiePopup from './widgets/cookie-popup'
import FilteredList from './widgets/filtered-list'
import { loadImage } from './widgets/image'
import { findPasswordToggle } from './widgets/password'
import DatePicker from './widgets/datepicker'
import Modal from './widgets/modal'
import { shuffleArray } from './helpers' 

$(() => {
    new FilteredList();

    loadImage($('img'));

    findPasswordToggle();

    $('.autofocus').focus();

    $('.hide-js').hide();

    $('.back-to-top').on('click', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 'slow');
        return false;
    });

    $(window).on('scroll', function () {
        $('.back-to-top').toggleClass('show', $(window).scrollTop() > 200);
    });

    $('.mobile-nav-toggle, .nav-toggle').on('click touchstart', function () {
        const but = $(this);
        but.toggleClass('open');
        $('#main-nav').toggleClass('open');
        if ($('#main-nav').hasClass('open')) {
            $(document.body).one('click', () => {
                but.removeClass('open');
                $('#main-nav').removeClass('open');
            });
        }
        return false;
    }).one('mousedown', () => {
        const trendingTopicsNav = $('#toptopics-nav, #trending-nav');
        if (trendingTopicsNav.length) {
            $.get("/snippets/MenuTrending").then((data) => {
                $('#toptopics-nav').html(data.topHtml);
                $('#trending-nav').html(data.trendingHtml);
            });
        }
    });

    $('.nav-overlay button[aria-controls]').on('click', (e) => {
        e.stopPropagation();
        e.preventDefault();

        const $li = $(`#${$(e.currentTarget).attr("aria-controls")}`).parents('li');
        $li.toggleClass("group-open");
        $(e.currentTarget).attr("aria-expanded", $li.hasClass("group-open"));
    })
    $('.nav-overlay button[aria-controls][aria-expanded="true"]').each((i, el) => {
        $(`#${$(el).attr("aria-controls")}`).parents('li').addClass('group-open');
    });

    $('.dropdown.sections a').on('click', function () {
        var dropdown = $(this).parents('.dropdown').addClass('close');
        setTimeout(function () { dropdown.removeClass('close'); }, 10);
    });

    new CookiePopup();

    $('.dropdown input').on('change', e => {
        if (e.currentTarget.checked) {
            $(e.currentTarget).parents('.dropdown').find('.label').html($(e.currentTarget).next().html());
        }
    });

    $('[href="#toggle-subscription"], [data-action="toggle-subscription"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        item.toggleClass('off');
        $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        $.post('/User/Subscription/Toggle/' + id).then(result => {
            item.toggleClass('off', result.disabled);
            $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        });
        return false;
    });

    $('[href="#remove-subscription"], [data-action="remove-subscription"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Unsubscribe/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    $('[data-action="subscribe"]').click(ev => {
        const target = ev.currentTarget;
        const $target = $(target);
        const item = $target.parents('[data-id]');
        const id = item.attr('data-id');

        if (target.disabled || !id) { return false; }

        const titleOn = $target.attr('data-title-on');
        const titleOff = $target.attr('data-title-off');
        const analyticsMonitoringMode = titleOn && titleOff;

        if (analyticsMonitoringMode && $target.attr('data-on') !== undefined) {
            $.post('/User/Unsubscribe/' + id).then(result => {
                if (!result.error) {
                    item.addClass('analytics-list__item--uncovr')
                    $target.attr("data-on", null);
                    $target.attr("title", $target.attr("data-title-off"));
                    item.find('.analytics-list__tools > *').attr('disabled', true);
                } else {
                    new Modal().show(result.msg);
                }
            });
        } else {
            $.post('/User/Subscribe/' + id).then(result => {
                if (!result.error) {
                    item.removeClass('analytics-list__item--uncovr')
                    item.find('[disabled]').removeAttr('disabled');
                    if (analyticsMonitoringMode) {
                        $target.attr("data-on", true);
                    } else {
                        target.disabled = true;
                    }
                } else {
                    new Modal().show(result.msg);
                }
            });
        }
        return false;
    }).filter("[data-title-on][data-title-off]").each((idx, el) => {
        $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on" : "off"}`))
    });;

    $('[data-action="save-search"]').on('click', (ev) => {
        const target = ev.currentTarget;
        const $target = $(target);
        const search = $target.attr('data-search');
        if (target.disabled || !search) return false;

        const titleOn = $target.attr('data-title-on');
        const titleOff = $target.attr('data-title-off');
        const analyticsMonitoringMode = titleOn && titleOff;
        if (analyticsMonitoringMode && $target.attr('data-on') !== undefined) {
            const id = $target.parents('[data-id]').attr("data-id");
            if (!id) { return false }
            $.post(`/User/Search/Delete/${encodeURIComponent(id)}`).then(result => {
                if (!result.error) {
                    $target.attr("data-on", null);
                    $target.attr("title", $target.attr("data-title-off"));
                    $target.parents('.analytics-list__item')
                        .addClass('analytics-list__item--uncovr')
                        .attr('data-id', null)
                        .find('.analytics-list__tools > *')
                            .attr('disabled', true);
                } else {
                    new Modal().show(result.msg);
                }
            });
        } else {
            $.post(`/User/Search/Save/${encodeURIComponent(search)}/Alle`).then(result => {
                if (!result.error) {
                    if (analyticsMonitoringMode) {
                        $target.attr("data-on", true);
                    } else {
                        target.disabled = true;
                    }
                    $target.parents('.analytics-list__item')
                        .removeClass('analytics-list__item--uncovr')
                        .attr('data-id', result.id)
                        .find('.analytics-list__tools > *')
                            .attr('disabled', false);
                } else {
                    new Modal().show(result.msg);
                }
            });
        }
        return false;
    }).filter("[data-title-on][data-title-off]").each((idx, el) => {
        $(el).attr("title", $(el).attr(`data-title-${$(el).attr("data-on") !== undefined ? "on" : "off"}`))
    });

    $('[href="#toggle-filter"], [data-action="toggle-filter"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        item.toggleClass('off');
        $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        $.post('/User/Search/Toggle/' + id).then(result => {
            item.toggleClass('off', result.disabled);
            $(this).attr('title', $(this).attr(`data-title-${item.hasClass('off') ? 'disabled' : 'enabled'}`));
        });
        return false;
    });

    $('[href="#remove-filter"], [data-action="remove-filter"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Search/Delete/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    $('[href="#toggle-filter-reference"], [data-action="toggle-filter-reference"]').click(function () {
        if (this.disabled) { return false; }
        const item = $(this).parents('[data-id]');
        const id = item.attr('data-id');
        const done = item.slideUp().promise();
        $.post('/User/Search/ToggleReference/' + id).then(result => {
            if (result.error) {
                item.slideDown();
            } else {
                done.then(() => item.remove());
            }
        });
        return false;
    });

    function updatePricingTableInterval() {
        const checked = $('#pricing-toggle').is(':checked');
        $('.user-pricing__package--yearly').toggle(checked)
        $('.user-pricing__package--monthly').toggle(!checked)
    }
    $('#pricing-toggle').change(updatePricingTableInterval);
    updatePricingTableInterval();

    $('.user-pricing__package input').change((e) => {
        $(e.target).parents('form').submit();
    });

    $('[href="#cancel-package"]').on('click', e => {
        const content = $(`<div>
            Sind Sie sicher, dass Sie Ihr Abo wirklich kündigen wollen? Das Abo endet damit am ${$(e.currentTarget).attr('data-date')} und wird nicht automatisch verlängert.
            <div class="modal-content__button-row modal-content__button-row--left">
                <button id="yes-cancel-package">Ja, Abo kündigen</button>
                <button class="inverted-blue-bordered" id="no-cancel-package">Nein, doch nicht</button>
            </div>
        </div>`);
        const modal = new Modal().show(content, "Abo wirklich kündigen?");
        content.find('#yes-cancel-package').on('click', () => {
            modal.progress();
            $.post('/CancelPackage').then(result => {
                modal.progress(false);
                if (result.error) {
                    modal.content(result.msg, "Abo kündigen");
                } else {
                    modal.content(result.msg, "Abo kündigen");
                }
            })
        })
        content.find('#no-cancel-package').on('click', () => {
            modal.close();
        })
        return false;
    });

    $('#ressort-select > *').on('click', () => {
        $('#ressort-list')
            .toggleClass('head__ressort-list--show')
            .height($('#ressort-list').hasClass('head__ressort-list--show') ? $('#ressort-list')[0].scrollHeight : 0);
    })

    if ($('.ressort-highlights').length) {
        $('.ressort-highlights').css('--height', $('.ressort-highlights')[0].scrollHeight + 'px');
    }

    //trending date picker
    if ($('.dropdown.date-trending').length) {
        new DatePicker($('.dropdown.date-trending'), {
            onChange: value => {
                window.location = `/News/Trending/${value.replaceAll(".", "-")}`;
            }
        });
    }

    //trending hero
    if ($('.trending-hero').length) {
        const options = shuffleArray($('.trending-list')
            .map((idx, el) => $(el).find('li').slice(0, 3))
            .toArray()
            .reduce((agg, items) => [...agg, ...items], []));
        let currentIndex = 0;

        function scheduleTrendingHeroUpdate() {
            setTimeout(() => {
                $('.trending-hero__progress').css({ animation: "trending-reset 0s 0s both 1" });
                setTimeout(() => {
                    $('.trending-hero__progress').css({ animation: "" });
                }, 1);

                currentIndex++;
                if (currentIndex >= options.length) {
                    currentIndex = 0;
                }

                updateTrendingHero();
                scheduleTrendingHeroUpdate();
            }, 6100);
        }

        function updateTrendingHero() {
            const $item = $(options[currentIndex]);
            const keyword = $item.find('.trending-list__label').text().trim();
            const title = $item.parents('section').find('.trending-list__title').text();

            $('.trending-hero__title').html(`${title}<br><em>${keyword}</em>`);
            $('#search_field').val(`#${keyword}`)
        }

        updateTrendingHero();
        scheduleTrendingHeroUpdate();
    }

    //trending news
    if ($('.trending-news').length) {
        fetch('https://presse.uncovr.com/Ajax.aspx/GetNewsData', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                checkID: "0f8fad5b-d9cb-469f-a165-70867728950e",
                server: "https://presse.uncovr.com/",
                clientID: 25,
                groupID: 285,
                masterGroupID: 285,
                languageID: 23,
                menueID: 31132,
                treshold: 4,
                isLogged: false,
                newsId: -1,
                startIndex: 1,
            })
        }).then(d => d.json())
            .then(({ d }) => {
            for (let item of d.Items) {
                $('.trending-news').append(`<div class="trending-news__item col-xs-12 col-sm-3">
                    <div class="trending-news__image">${item.Image.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <div class="trending-news__title">${item.Title.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <div class="trending-news__text">${item.Text.replace(/<a [^>]+>(.*)<\/a>/g, "$1")}</div>
                    <a class="trending-news__link" target="_blank" href="${item.Title.match(/href="([^"]+)"/)[1]}"></a>
                </div>`);
            }
        });
    }

    //jotforms
    $('a[href^="#jotform-"]').each((idx, el) => {
        $(el).on('click', (e) => {
            e.stopPropagation();
            e.preventDefault();

            if (!window["JotformFeedback"]) {
                return false;
            }

            const id = $(e.currentTarget).attr('href').split('-')[1];

            new JotformFeedback({
                formId: id,
                base: 'https://form.jotform.com/',
                background: '#FFA500',
                fontColor: '#FFFFFF',
                type: 1,
                height: 500,
                width: 700,
                openOnLoad: true
            });

            return false;
        });
    });


    //news ticker
    const newsTicker = $('.news-ticker');
    if (newsTicker.length) {
        const token = newsTicker.attr("data-token");
        fetch(`/Json/${token}/uncovrTop_500/1`).then((d) => d.json()).then((data) => {
            const html = [...new Set(data.items.map((item) => `<li>${item.company.shortName}</li>`))].join('');
            newsTicker.html(`<ul class="news-ticker__list">${html}</ul>`);
            const list = newsTicker.children().first();
            const lw = list.width();
            list.html(html + html);
            let lastT = performance.now();
            let shift = 0;
            const tick = (t) => {
                const dt = Math.round((t - lastT) * .2);
                lastT = t;
                shift = (shift - dt) % lw;
                list.css("transform", `translateX(${shift}px)`);
                requestAnimationFrame(tick);
            };
            tick(lastT);
        });
    }


    //trending news
    const trendingTopicNewsTemplate = $('#trending-topics-article')[0];
    $('.trending-topics__item').each((i, el) => {
        const item = $(el);

        fetch(item.attr("data-url")).then(d => d.json()).then(data => {
            for (let newsItem of data.items) {
                const news = trendingTopicNewsTemplate.content.cloneNode(true);
                $(news).find('.trending-topics__article__title').html(newsItem.title);
                $(news).find('.trending-topics__link')
                    .html(`<span class="icon icon-export"></span> ${newsItem.url.replace(/https?:\/\//, '').split('/')[0]}`)
                    .attr('href', newsItem.url);

                item.append(news);
            }
        }); 
    });

    //uncovr news
    let uncovrNewsRessortsPromise;
    let uncovrNewsMode = localStorage.getItem("uncovrNewsMode") || "ressort";
    const uncovrNewsWrapper = $('.uncovr-news__wrapper');
    const uncovrNewsDetails = $('.uncovr-news-article[data-id]');

    function urlTitle(string) {
        return string
            .toLowerCase()
            .replaceAll("ö", "oe")
            .replaceAll("ü", "ue")
            .replaceAll("ä", "ae")
            .replaceAll("ß", "ss")
            .replace(/[^0-9a-z ]/g, '')
            .replaceAll(' ', '-')
    }

    async function loadNewsRessorts(force) {
        if (!uncovrNewsRessortsPromise || force) {
            uncovrNewsRessortsPromise = new Promise(async function(resolve) {
                const ressorts = {};
                const order = [];
                const loadedRessorts = await fetch("https://projekte.wunderwerk.at/uncovr-news/FE/api/v2/resorts").then((d) => d.json());
                for (let ressort of loadedRessorts) {
                    ressorts[ressort.Id] = {
                        id: ressort.Id,
                        name: ressort.Name,
                        color: ressort.Color
                    }
                    order.push(ressort.Id);
                }
                resolve({
                    ressorts,
                    order
                });
            });
        }

        return uncovrNewsRessortsPromise;
    }

    async function parseApiNews(data) {
        return {
            id: data.Id,
            ressort: (await loadNewsRessorts()).ressorts[data.ResortId],
            title: data.Title,
            text: data.Text,
            imageId: data.ImageId,
            date: new Date(data.Date),
            editor: data.Editor,
            source: data.Source,
            tags: data.Tags,
            video: data.Video,
            mediaSource: data.MediaSource
        }
    }

    /**
     * @param {{date: string, maxAgeHours: number, resortId: number, tags: string, topNews: boolean, skip: number, take: number }} options
     */
    async function loadNewsList(options) {
        const searchParams = new URLSearchParams(options);
        return await fetch(`https://projekte.wunderwerk.at/uncovr-news/FE/api/v2/news?${searchParams.toString()}`).then((d) => d.json()).then((d) => Promise.all(d.map(parseApiNews)));
    }

    async function loadNews(id) {
        return await fetch(`https://projekte.wunderwerk.at/uncovr-news/FE/api/v2/news/${id}`).then((d) => d.json()).then(parseApiNews);
    }

    const newsImageObserver = new IntersectionObserver((entries) => {
        if (entries.length) {
            for (let entry of entries) {
                if (entry.isIntersecting && !entry.target.src) {
                    entry.target.src = entry.target.getAttribute("data-src");
                }
            }
        }
    });

    function handleNewsImages(el) {
        $(el).find(".uncovr-news__item__image[data-src]").each((i, img) => {
            newsImageObserver.observe(img);
            img.addEventListener("load", () => {
                img.classList.add("loaded");
            });
        });
    }

    async function fillByDate() {
        $('.uncovr-news').remove();

        const baseDate = new Date();

        for (let d = 0; d < 7; d++) {
            const date = new Date(baseDate);
            date.setDate(date.getDate() - d);

            const newsList = await loadNewsList({ date: `${date.getFullYear()}-${(date.getMonth() +1).toString().padStart(2, "0") }-${date.getDate().toString().padStart(2, "0") }` });
            const newsEls = [];

            if (uncovrNewsMode != "date") return;
            if (!newsList.length) continue;

            for (let news of newsList) {
                newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}" style="--ressort-color: ${news.ressort.color}">
                        <img class="uncovr-news__item__image" data-src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                        <div class="uncovr-news__item__content">
                            <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                        </div>
                        <a class="uncovr-news__item__link-overlay" href="/News/Article/${urlTitle(news.title)}-${news.id}"></a>
                    </article>`);
            }

            const newsSectionEl = $(`<section class="uncovr-news uncovr-news--by-date"></section>`);

            const dateEl = $(`<div class="uncovr-news__date__wrapper">
                        <h2 class="uncovr-news__date"><strong>${['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'][date.getDay()]}</strong>${date.getDate().toString().padStart(2, "0")}.${(date.getMonth() + 1).toString().padStart(2, "0")}.</h2>
                    </div>`);
            newsSectionEl.append(dateEl);

            newsSectionEl.append(newsEls.join(''));

            uncovrNewsWrapper.append(newsSectionEl);

            handleNewsImages(newsSectionEl);
        }
    }

    async function fillByRessort() {
        const { ressorts, order } = await loadNewsRessorts();

        $('.uncovr-news').remove();

        for (let ressortId of order) {
            const ressort = ressorts[ressortId];
            const newsEls = [];

            const newsList = await loadNewsList({ resortId: ressortId, take: 12 });

            if (uncovrNewsMode != "ressort") return;

            let nc = 0;
            for (let news of newsList) {
                newsEls.push(`<article class="uncovr-news__item ${news.video ? "uncovr-news__item--video" : ""}">
                            <img class="uncovr-news__item__image" data-src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                            <div class="uncovr-news__item__content">
                                <h3 class="uncovr-news__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h3>
                                <div class="uncovr-news__item__text">${news.text}</div>
                                ${news.source ? `<a class="uncovr-news__item__link" href="${news.source}" target="_blank">${news.source.replace(/https?:\/\//, '').split('/')[0]}</a>` : ''}
                            </div>
                            <a class="uncovr-news__item__link-overlay" href="/News/Article/${urlTitle(news.title)}-${news.id}"></a>
                        </article>`);
            }

            const newsSectionEl = $(`<section class="uncovr-news" style="--ressort-color:${ressort.color}"></section>`);
            const ressortEl = $(`<div class="uncovr-news__ressort__wrapper">
                        <h2 class="uncovr-news__ressort">${ressort.name}</h2>
                    </div>`);

            newsSectionEl.append(ressortEl);

            newsSectionEl.append(newsEls.join(''));

            uncovrNewsWrapper.append(newsSectionEl);

            handleNewsImages(newsSectionEl);
        }
    }

    function fillNews(mode) {
        if (mode == "date") {
            fillByDate();
        } else {
            fillByRessort();
        }
    }

    if (uncovrNewsWrapper.length) {

        const toggles = document.createElement("div");
        toggles.classList.add("uncovr-news__filter")

        toggles.innerHTML = `
                <button class="uncovr-news__filter__button ${uncovrNewsMode == "ressort" ? "uncovr-news__filter__button--active" : ""}" data-sort-by="ressort">Thematisch</button>
                <button class="uncovr-news__filter__button ${uncovrNewsMode == "date" ? "uncovr-news__filter__button--active" : ""}" data-sort-by="date">Chronologisch</button>
            `;

        uncovrNewsWrapper.append(toggles);

        for (let toggle of toggles.querySelectorAll('.uncovr-news__filter__button')) {
            toggle.addEventListener('click', (ev) => {
                const mode = ev.currentTarget.getAttribute("data-sort-by");
                if (mode != uncovrNewsMode) {
                    uncovrNewsMode = mode;
                    localStorage.setItem("uncovrNewsMode", mode);
                    fillNews(mode);
                    ev.currentTarget.parentElement.querySelector('.uncovr-news__filter__button--active')?.classList.remove('uncovr-news__filter__button--active')
                    ev.currentTarget.classList.add('uncovr-news__filter__button--active');
                }
            });
        }

        fillNews(uncovrNewsMode);
    }

    if (uncovrNewsDetails.length) {
        uncovrNewsDetails.each(async (idx, el) => {
            const news = await loadNews(el.getAttribute("data-id").split('-').pop());

            el.style.setProperty("--ressort-color", news.ressort.color);

            document.title = `${news.title} | uncovr`

            el.innerHTML += `
                ${news.video
                ? `<div class="uncovr-news-article__video" style="--image-src: url(${`https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}`})" data-video-id="${news.video}">
                        <div class="uncovr-news-article__video__background"></div>
                        <div id="youtube-player-1" class="uncovr-news-article__video__placeholder"></div>
                    </div>`
                    : `<img class="uncovr-news-article__image" src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">`}
                <div class="uncovr-news-article__content">
                    <h1 class="uncovr-news-article__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</h1>
                    <div class="uncovr-news-article__text">${news.text.replace(/(https?:\/\/([^\s]+?)\/?\s)/g, '<a href="$1" target="_blank">$2</a> ')}</div>
                </div>
                <div class="uncovr-news-article__info">
                    <div class="uncovr-news-article__ressort-wrapper">
                        <h2 class="uncovr-news-article__ressort">${news.ressort.name}</h2>
                    </div>
                    <div class="uncovr-news-article__info__content">
                        <div class="uncovr-news-article__info__date">${news.date.getDate().toString().padStart(2, "0")}.${(news.date.getMonth() + 1).toString().padStart(2, "0")}.${news.date.getFullYear()}</div>
                        <hr />
                        ${news.source ? `<div class="uncovr-news-article__info__source"><strong>Originalquelle</strong> <a href="${news.source}" target="_blank"><span class="icon icon-export"></span> ${news.source.replace(/https?:\/\//, '').split('/')[0]}</a></div>` : ''}
                        ${news.mediaSource
                            ? news.mediaSource.startsWith("http") 
                                ? `<div class="uncovr-news-article__info__media-source"><strong>Bildquelle</strong> <a href="${news.mediaSource}" target="_blank"><span class="icon icon-export"></span> ${news.mediaSource.replace(/https?:\/\//, '').split('/')[0]}</a></div>`
                                : `<div class="uncovr-news-article__info__media-source"><strong>Bildrechte</strong> ${news.mediaSource}</div>`
                            : ''
                        }
                        <button class="black-bordered small" style="display: flex; gap: .5rem; font-weight: 300; font-size: .875rem" data-success="Kopiert!" data-error="Fehler!">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 1rem;">
                                <path d="M272 416C263.2 416 256 423.2 256 432V448c0 17.67-14.33 32-32 32H64c-17.67 0-32-14.33-32-32V192c0-17.67 14.33-32 32-32h112C184.8 160 192 152.8 192 144C192 135.2 184.8 128 176 128H63.99c-35.35 0-64 28.65-64 64l.0098 256C0 483.3 28.65 512 64 512h160c35.35 0 64-28.65 64-64v-16C288 423.2 280.8 416 272 416zM502.6 86.63l-77.25-77.25C419.4 3.371 411.2 0 402.7 0H288C252.7 0 224 28.65 224 64v256c0 35.35 28.65 64 64 64h160c35.35 0 64-28.65 64-64V109.3C512 100.8 508.6 92.63 502.6 86.63zM416 45.25L466.7 96H416V45.25zM480 320c0 17.67-14.33 32-32 32h-160c-17.67 0-32-14.33-32-32V64c0-17.67 14.33-32 32-32h96l.0026 64c0 17.67 14.33 32 32 32H480V320z"/>
                            </svg>
                            Meldungstext kopieren
                        </button>
                    </div>
                </div>
                <aside class="uncovr-news-article__more">
                    <h1 class="uncovr-news-article__more__title">Mehr aus <strong>${news.ressort.name}</strong></h1>
                    <div class="uncovr-news-article__more__items uncovr-news-article__more__items--vertical"></div>
                </aside>
                <aside class="uncovr-news-article__footer">
                    <h1 class="uncovr-news-article__more__title">Mehr aus <strong>${news.ressort.name}</strong></h1>
                    <div class="uncovr-news-article__more__items uncovr-news-article__more__items--horizontal"></div>
                </aside>
            `;

            $("button", el).on('click', async function (ev) {
                ev.currentTarget.classList.remove("success", "error");
                try {
                    await navigator.clipboard.writeText(`${news.title}\n\n${news.text}`);
                    ev.currentTarget.offsetHeight;
                    $(ev.currentTarget).addClass("success");
                } catch (error) {
                    console.error(error.message);
                    $(ev.currentTarget).addClass("error");
                }
            });

            setupVideo(el);

            const moreAside = $('.uncovr-news-article__more .uncovr-news-article__more__items');
            const moreFooter = $('.uncovr-news-article__footer .uncovr-news-article__more__items');
            //fetch more
            loadNewsList({ resortId: news.ressort.id, take: 12 }).then(async function (d) {
                for (let news of d) {
                    moreAside[0].innerHTML += `
                        <a class="uncovr-news-article__more__item" href="/News/Article/${urlTitle(news.title)}-${news.id}">
                            <div class="uncovr-news-article__more__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</div>
                        </a>
                    `;

                    moreFooter[0].innerHTML += `
                        <a class="uncovr-news-article__more__item" href="/News/Article/${urlTitle(news.title)}-${news.id}">
                            <img class="uncovr-news-article__more__item__image" src="https://projekte.wunderwerk.at/uncovr-news/FE/Images/Get/${news.imageId}" alt="image accompanying press release">
                            <div class="uncovr-news-article__more__item__title"><strong>${news.tags[0] || ""}</strong> ${news.title}</div>
                        </a>
                    `;
                }
            });
        });


        //video handling
        
        var ytApiPromise = null;
        function loadYTApi() {
            if (!ytApiPromise) {
                var tag = document.createElement('script');
                tag.src = "https://www.youtube.com/player_api";
                var firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                ytApiPromise = new Promise((resolve) => {
                    window.onYouTubePlayerAPIReady = () => {
                        resolve();
                    }
                })
            }

            return ytApiPromise;
        }

        function setupVideo(el) {
            var videoWrappers = document.querySelectorAll('.uncovr-news-article__video');

            for (let videoWrapper of videoWrappers) {
                videoWrapper.addEventListener('click', (e) => {
                    const id = e.currentTarget.getAttribute('data-video-id') || 'vo4rbhR_vsk';
                    videoWrapper.classList.add('uncovr-news-article__video--loading');
                    const paddingTop = parseInt(getComputedStyle(videoWrapper).paddingTop);
                    console.log(videoWrapper.offsetHeight);
                    loadYTApi().then(() => {
                        var player = new YT.Player(videoWrapper.querySelector('.uncovr-news-article__video__placeholder').id, {
                            height: videoWrapper.offsetHeight - paddingTop,
                            width: videoWrapper.offsetWidth,
                            videoId: id,
                            playerVars: {
                                autoplay: 1,
                                showinfo: 0,
                                autohide: 1,
                                modestbranding: 1
                            },
                        });
                        player.addEventListener('onReady', () => {
                            videoWrapper.classList.remove('uncovr-news-article__video--loading');
                            videoWrapper.classList.add('uncovr-news-article__video--playing');
                            player.playVideo();
                        })
                    })
                })
            }
        }
    }
});


